import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Styles
import { Container, Backdrop, Content } from './styles';

// Assets
import goldenWeekPopup from '../../assets/images/golden-weeks-popup.png';

export default function Popup() {
  // State
  const [isPopupShown, setIsPopupShown] = useState(
    !sessionStorage.getItem('@Miquelao:goldenWeeksShown'),
  );

  // Verifica data
  useEffect(() => {
    const isBeforeDate = moment(new Date()).isBefore('10/01/2019');

    if (!isBeforeDate) setIsPopupShown(false);
  }, []);

  function handlePopupClose() {
    // Session storage status
    sessionStorage.setItem('@Miquelao:goldenWeeksShown', true);
    // Fechar modal
    setIsPopupShown(false);
  }

  if (isPopupShown) {
    return (
      <Container>
        <Backdrop onClick={handlePopupClose} />
        <Content>
          <a
            href="http://goldenweeks.conceitoimoveispg.com.br/golden"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={goldenWeekPopup} alt="Golden Week" />
          </a>
          <button type="button" onClick={handlePopupClose}>
            X
          </button>
        </Content>
      </Container>
    );
  }

  return <></>;
}
